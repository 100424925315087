import Icon from "@/components/primitives/icon";
import {
  GameBasic,
  GameStatus,
  ScoreboardGame,
  ScoreboardGameBasic,
} from "common";
import { GameOdds } from "common";
import { TeamBasic } from "common";
import OddsRow from "./row";
import { OddCellColor } from "./cell";

export interface OddsGroupProps {
  game?: ScoreboardGameBasic;
  lockSide: "top-right" | "right";
  color?: OddCellColor;
  record?: boolean;
  startsNow?: boolean;
}

export default function OddsGroup(props: OddsGroupProps) {
  return (
    <div
      className={`flex flex-col relative justify-center gap-1.5 w-full scale-90 min-[360px]:scale-100 ${
        props.color === "dark" ? "bg-secondary-100" : "bg-surface-400"
      } rounded-md py-2 px-4`}
    >
      {props.game?.status === "InProgress" && (
        <div
          className={`bg-dark flex justify-center items-center ${
            props.color !== "dark" && "border-[3px] border-light"
          } absolute w-8 h-8 rounded-full text-primary-500 ${
            props.lockSide === "right" ? "-right-3" : "-top-4 -right-2"
          }`}
        >
          <Icon name="lock" size="sm" />
        </div>
      )}
      {/* AWAY ROW */}
      <OddsRow
        alignment="away"
        game={props.game}
        color={props.color}
        record={props.record}
        startsNow={props.startsNow}
      />
      {/* HOME ROW */}
      <OddsRow
        alignment="home"
        game={props.game}
        color={props.color}
        record={props.record}
        startsNow={props.startsNow}
      />
    </div>
  );
}
